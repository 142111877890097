import React from 'react';
import { Container } from 'react-bootstrap';

const About = () => {
    return (
        <Container className="mt-5">
            <div className="header-bar">
                <h2>Hakkımızda</h2>
            </div>

            <p>Salonumuz 2005 yılında kuruldu ve o günden bu yana müşterilerimize en kaliteli hizmeti sunmayı amaçlıyoruz. Güzellik sektöründe yılların verdiği tecrübe ile sizlere en iyi deneyimi yaşatıyoruz.</p>
            <h3>Ekibimiz</h3>
            <p>Uzman kadromuz, saç tasarımından cilt bakımına kadar geniş bir yelpazede hizmet sunmaktadır. Her bir ekibimiz, kendi alanında profesyonel ve eğitimli olup, sizlere en iyi hizmeti sunmak için çalışmaktadır.</p>
        </Container>
    );
};

export default About;
