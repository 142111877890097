import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { FaSun, FaMoon } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Logo resminizin yolu

const Header = ({ onToggleDarkMode, isDarkMode }) => {
    return (
        <Navbar className="navbar-custom" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <div className='logo-container'>
                        <img
                            src={logo}
                            alt="Ebru Güzellik Salonu"
                            style={{ borderRadius: '15px', width: '150px', height: 'auto' }}
                        />
                    </div>
                </Navbar.Brand>
                <div className="ms-auto"> {/* Butonu sağa dayalı yap */}
                    <Button className="dark-mode-button" variant="outline-light" onClick={onToggleDarkMode}>
                        {isDarkMode ? <FaSun /> : <FaMoon />}
                    </Button>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link as={Link} to="/">ANASAYFA</Nav.Link>
                        <Nav.Link as={Link} to="/services">HIZMETLER</Nav.Link>
                        <Nav.Link as={Link} to="/galery">GALERI</Nav.Link>
                        <Nav.Link as={Link} to="/products">URUNLER</Nav.Link>
                        <Nav.Link as={Link} to="/blog">BLOG</Nav.Link>
                        <Nav.Link as={Link} to="/appointment">RANDEVU</Nav.Link>
                        <Nav.Link as={Link} to="/contact">ILETISIM</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;