import React from 'react';
import { Container } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import './Footer.css'; // CSS dosyanızın yolu

const Footer = () => {
    return (
        <footer className="footer  text-light text-center text-lg-start">
            <Container className="p-3">
                <div className="text-center">
                    <h5>Bizi Takip Edin</h5>
                    <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer">
                        <FaFacebook size={30} style={{ margin: '0 10px', color: '#4267B2' }} />
                    </a>
                    <a href="https://www.instagram.com/yourprofile" target="_blank" rel="noopener noreferrer">
                        <FaInstagram size={30} style={{ margin: '0 10px', color: '#C13584' }} />
                    </a>
                    <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp size={30} style={{ margin: '0 10px', color: '#25D366' }} />
                    </a>
                </div>
                
                <div className="text-center mt-3">
                    <p>© 2024 MDSiber Savunma | All Rights Reserved</p>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
