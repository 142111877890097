import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import './Gallery.css'; // CSS dosyanızın yolu

const galleryImages = [
    ['assets/galery/makeup.jpg', 'assets/galery/makeup2.jpg', 'assets/galery/makeup3.jpg'],
    ['assets/galery/masaj.jpg', 'assets/galery/masaj2.jpg', 'assets/galery/masaj3.jpg'],
    ['assets/galery/zayif.jpg', 'assets/galery/zayif2.jpg', 'assets/galery/zayif3.jpg']
];

const Gallery = () => {
    return (
        <Container className="mt-5">
            <div className="header-bar text-center mb-4">
                <h2>Galeri</h2>
            </div>

            <Row>
                {/* İlk Carousel */}
                <Col md={4} className="mb-4">
                    <Carousel>
                        {galleryImages[0].map((image, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block w-100"
                                    src={`/${image}`}
                                    alt={`Slide ${index + 1}`}
                                    style={{ height: '400px', objectFit: 'cover' }}
                                />

                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>

                {/* İkinci Carousel */}
                <Col md={4} className="mb-4">
                    <Carousel>
                        {galleryImages[1].map((image, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block w-100"
                                    src={`/${image}`}
                                    alt={`Slide ${index + 4}`}
                                    style={{ height: '400px', objectFit: 'cover' }}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>

                {/* Üçüncü Carousel */}
                <Col md={4} className="mb-4">
                    <Carousel>
                        {galleryImages[2].map((image, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block w-100"
                                    src={`/${image}`}
                                    alt={`Slide ${index + 7}`}
                                    style={{ height: '400px', objectFit: 'cover' }}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
};

export default Gallery;
