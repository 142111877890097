import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
    return (
        <Container className="mt-5">
            <div className="header-bar">
                <h2>Bize Ulaşın</h2>
            </div>

            <Form>
                <Form.Group controlId="formContactName">
                    <Form.Label>Adınız</Form.Label>
                    <Form.Control type="text" placeholder="Adınızı girin" />
                </Form.Group>

                <Form.Group controlId="formContactEmail">
                    <Form.Label>E-posta</Form.Label>
                    <Form.Control type="email" placeholder="E-postanızı girin" />
                </Form.Group>

                <Form.Group controlId="formContactMessage">
                    <Form.Label>Mesajınız</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Mesajınızı buraya yazın" />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Gönder
                </Button>
            </Form>

            <div className="mt-4">
                <h4>Salon Adresimiz</h4>
                <p>
                    <FaMapMarkerAlt /> <a href="https://www.google.com/maps/place/Örnek+Sokak+No:+123,+İstanbul" target="_blank" rel="noopener noreferrer">Örnek Sokak No: 123, İstanbul</a>
                </p>
                <p>
                    <FaPhoneAlt /> <a href="tel:+1234567890">(123) 456-7890</a>
                </p>
                <p>
                    <FaEnvelope /> <a href="mailto:info@beautysalon.com">info@beautysalon.com</a>
                </p>
            </div>
        </Container>
    );
};

export default Contact;
