import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';

const Appointment = () => {
    // Varsayılan tarih ve saat değerlerini belirleyin
    const defaultDate = new Date().toISOString().split('T')[0]; // Bugünün tarihi
    const defaultTime = new Date().toTimeString().split(' ')[0].substring(0, 5); // Mevcut saat


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        date: defaultDate,
        time: defaultTime,
        service: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Mesajı oluştur
        const message = `Ad: ${formData.name}\nE-posta: ${formData.email}\nTarih: ${formData.date}\nSaat: ${formData.time}\nHizmet: ${formData.service}`;

        // WhatsApp URL oluştur
        const whatsappURL = `https://api.whatsapp.com/send?phone=5331199013&text=${encodeURIComponent(message)}`;

        // WhatsApp uygulamasını aç
        window.open(whatsappURL, '_blank');
    };

    return (
        <Container className="mt-5">
            <div className="header-bar">
                <h2>Randevu Al</h2>
            </div>

            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                    <Form.Label>Adınız</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formEmail">
                    <Form.Label>E-posta</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formDate">
                    <Form.Label>Tarih</Form.Label>
                    <Form.Control
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formTime">
                    <Form.Label>Saat</Form.Label>
                    <Form.Control
                        type="time"
                        name="time"
                        value={formData.time}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formService">
                    <Form.Label>Hizmet</Form.Label>
                    <Form.Control
                        as="select"
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Seçin</option>
                        <option value="haircut">Saç Kesimi</option>
                        <option value="skincare">Cilt Bakımı</option>
                        <option value="makeup">Makyaj</option>
                        <option value="makeup">Estetik</option>
                        <option value="makeup">Zayıflama</option>
                        <option value="makeup">Lazer</option>
                        <option value="makeup">pedikür</option>
                        <option value="makeup">keratin</option>
                        <option value="makeup">Boya</option>
                    </Form.Control>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Gönder
                </Button>
            </Form>
        </Container>
    );
};

export default Appointment;
