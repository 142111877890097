// Blog.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const blogPosts = [
    {
        title: 'Saç Bakımı İçin İpuçları',
        excerpt: 'Saç bakımı ile ilgili en iyi ipuçları ve öneriler.',
        date: '2024-09-01'
    },
    {
        title: 'Cilt Bakımında Yeni Trendler',
        excerpt: 'Cilt bakımında dikkat edilmesi gereken yeni trendler ve yöntemler.',
        date: '2024-09-10'
    },
    {
        title: 'Salonumuzda Neler Değişti?',
        excerpt: 'Son güncellemeler ve yenilikler hakkında bilgi.',
        date: '2024-09-15'
    }
];

const Blog = () => {
    return (
        <Container className="mt-5">
            <div className="header-bar">
                <h2>Blog</h2>
            </div>

            <Row>
                {blogPosts.map((post, index) => (
                    <Col key={index} md={4} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>{post.title}</Card.Title>
                                <Card.Text>{post.excerpt}</Card.Text>
                                <Card.Text><small className="text-muted">{post.date}</small></Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Blog;
