import React from 'react';
import { Carousel } from 'react-bootstrap';
import r1 from '../assets/slide1.jpg'
import r2 from '../assets/slide2.jpg'
import r3 from '../assets/slide3.jpg'

const BeautyCarousel = () => {
    return (
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={r1}
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h3>Güzellik Uzmanlarımız</h3>
                    <p>Profesyonel ekibimizle güzelliğinizi keşfedin.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={r2}
                    alt="Second slide"
                />
                <Carousel.Caption>
                    <h3>Kaliteli Ürünler</h3>
                    <p>Sadece en iyi ürünlerle çalışıyoruz.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={r3}
                    alt="Second slide"
                />
                <Carousel.Caption>
                    <h3>Kaliteli Ürünler</h3>
                    <p>Sadece en iyi ürünlerle çalışıyoruz.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
};

export default BeautyCarousel;
