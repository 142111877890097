import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import BeautyCarousel from './components/Carousel';
import Services from './components/Services';
import Products from './components/Products';
import Appointment from './components/Appointment';
import About from './components/About';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import Blog from './components/Blog';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import GoToTopButton from './components/GoToTopButton'; // Bileşen yolu

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };
  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
  }, [isDarkMode]);
  return (
    <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
      <Router>
        <Header onToggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
        <main className="flex-grow-1">
          <Routes>
            <Route path="/" element={
              <>
                <BeautyCarousel />
                <div className="divider"></div>

                <Services />
                <div className="divider"></div>

                <Products />
                <div className="divider"></div>

                <Gallery />
                <div className="divider"></div>

                <Blog />
              </>
            } />
            <Route path="/galery" element={<Gallery />} />
            <Route path="/products" element={<Products />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/services" element={<Services />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <div className="divider"></div>
        <Footer />
        <GoToTopButton />
      </Router>
    </div>
  );
}

export default App;
