import React from 'react';
import { Card, Container } from 'react-bootstrap';
import './Services.css'; // Mozaik stili için CSS dosyası ekleyin

const servicesList = [
    { title: 'Genosys', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/genosys.jpg' },
    { title: 'Saç Tasarımı', description: 'Profesyonel saç kesimi ve renklendirme.', image: 'assets/hizmetler/sac.jpg' },
    { title: 'Cilt Bakımı', description: 'Özel cilt bakım seansları.', image: 'assets/hizmetler/cilt_bakimi.jpg' },
    { title: 'Makyaj', description: 'Özel günler için profesyonel makyaj.', image: 'assets/hizmetler/make_up.jpg' },
    { title: 'Estetik Cerrahi', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/estetik.jpg' },
    { title: 'Masaj', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/masaj.jpg' },
    { title: 'Lazer', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/lazer.jpg' },
    { title: 'Brezilya Fönü', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/brezilya_fonu.jpg' },
    { title: 'Zayıflama', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/zayiflama.jpg' },
    { title: 'Kirpik Lifting', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/lifting.jpg' },
    { title: 'Boya', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/boya.jpg' },
    { title: 'Keratin', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/keratin.jpg' },
    { title: 'Pedikür', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/pedikur.jpeg' },
    { title: 'Kıl Teknik', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/kil.jpg' },
    { title: 'Kıl Teknik', description: 'Cerrahi İyileştirmeler.', image: 'assets/hizmetler/kil.jpg' },
];

const Services = () => {
    return (
        <Container className="mt-5">
            <div className="header-bar">
            <h2>Hizmetler</h2>
            </div>
            <div className="grid-container">
                {servicesList.map((service, index) => (
                    <div key={index} className={`grid-item grid-item-${index + 1}`}>
                        <Card>
                            <Card.Img variant="top" src={`/${service.image}`} className="service-image" />
                            <Card.Body>
                                <Card.Title>{service.title}</Card.Title>
                                <Card.Text>{service.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </Container>
    );
};

export default Services;
