import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

const productsList = [
    {
        title: 'GENOSYS',
        description: 'Cildinizi canlandıran doğal içeriklerle hazırlanmış özel bir krem.',
        image: 'assets/urunler/genosys.jpg'
    },
    {
        title: 'PH FORMULA',
        description: 'Saçlarınızı güçlendiren ve doğal parlaklık kazandıran özel formül.',
        image: 'assets/urunler/pH_formula.png'
    },
    {
        title: 'THERADERM',
        description: 'Göz alıcı renklerle dolu profesyonel makyaj seti.',
        image: 'assets/urunler/theraderm.png'
    },
    {
        title: 'ME LINE',
        description: 'Göz alıcı renklerle dolu profesyonel makyaj seti.',
        image: 'assets/urunler/me_line.png'
    },
    {
        title: 'FIDERM',
        description: 'Göz alıcı renklerle dolu profesyonel makyaj seti.',
        image: 'assets/urunler/fiderm.png'

    },
    {
        title: 'DECAAR',
        description: 'Göz alıcı renklerle dolu profesyonel makyaj seti.',
        image: 'assets/urunler/decaar.png'
    }
];

const Products = () => {
    return (
        <Container className="mt-5">
            <div className="header-bar">
                <h2>Ürünler</h2>
            </div>
            <Row>
                {productsList.map((product, index) => (
                    <Col key={index} md={4} className="mb-4">
                        <Card className="text-center">
                            {/* Yuvarlak resim ve stil ayarları */}
                            <Card.Img
                                variant="top"
                                src={`/${product.image}`}
                                style={{ width: '250px', height: '100px', margin: '0 auto', objectFit: 'contain' }}
                            />
                            <Card.Body>
                                <Card.Title>{ }</Card.Title>
                                <Card.Text>{product.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Products;
